import React from "react"

import Layout from "../components/layout"
import Profile from "../components/profile"

import Seo from "../components/seo"

const NotFoundPage = ( {location} ) => (
    <Layout>
      <Seo
      pagetitle="ページが見つかりません"
      pagepath={location.pathname}
      />
      <div className="contact">
      <h2 className="notFound">お探しのページが見つかりませんでした。</h2>
        <Profile />
      </div>
    </Layout>
)

export default NotFoundPage